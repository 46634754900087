.rg-celleditor-input, .rg-celleditor input {
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 1em;
}

.rg-celleditor {
  box-sizing: border-box;
  z-index: 5;
  background-color: #fff;
  border: 2px solid #3579f8;
  padding: 0 4px;
  display: flex;
  box-shadow: 1px 1px 6px #0000000f;
}

.rg-number-celleditor input {
  text-align: right;
}

.reactgrid-content .rg-pane .rg-cell.rg-number-cell, .reactgrid-content .rg-pane .rg-cell .rg-time-cell, .reactgrid-content .rg-pane .rg-cell .rg-date-cell {
  justify-content: flex-end;
}

.reactgrid-content .rg-pane .rg-cell.rg-email-cell.invalid {
  color: red;
}

.reactgrid-content .rg-pane .rg-cell.rg-text-cell.placeholder {
  color: #999;
  font-size: .8em;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell {
  pointer-events: auto;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell input {
  width: 20px;
  height: 20px;
}

.reactgrid-content .rg-pane .rg-cell.rg-chevron-cell .chevron {
  pointer-events: auto;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-right: .1em;
  font-weight: bold;
  transition: all .2s ease-in-out;
  display: flex;
}

.reactgrid-content .rg-pane .rg-cell.rg-chevron-cell .no-child {
  width: .5em;
}

.reactgrid-content .rg-pane .rg-cell.rg-chevron-cell.expanded .chevron {
  transform: rotate(90deg);
}

.reactgrid-content .rg-pane .rg-cell.rg-chevron-cell.collapsed .chevron {
  transform: rotate(0);
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell {
  justify-content: center;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label {
  cursor: pointer;
  user-select: none;
  width: 18px;
  height: 18px;
  margin: 0;
  position: relative;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input {
  opacity: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: 0;
  position: absolute;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell span {
  background-color: #eee;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label:hover input ~ span {
  background-color: #ccc;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span {
  background-color: #3579f8;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell span:after {
  content: "";
  display: none;
  position: absolute;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label input:checked ~ span:after {
  display: block;
}

.reactgrid-content .rg-pane .rg-cell.rg-checkbox-cell label span:after {
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  width: 4px;
  height: 12px;
  top: 0;
  left: 5px;
  transform: rotate(45deg);
}

.reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell {
  padding: 0;
  overflow: visible;
}

.reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-menu {
  z-index: 1;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 8px;
  position: absolute;
  top: 100%;
  box-shadow: 0 0 7px #00000080;
}

.reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option {
  align-items: center;
  min-height: 1.5em;
  padding: .3em .5em;
  display: flex;
}

.reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option.selected:before {
  content: "✓";
  padding-right: .2em;
}

.reactgrid-content .rg-pane .rg-cell.rg-dropdown-cell .rg-dropdown-option.focused {
  color: #000;
  background-color: #e2ebfe;
}

[contenteditable] {
  user-select: text;
}

.reactgrid-content {
  user-select: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.reactgrid-content .rg-pane.rg-pane-top, .reactgrid-content .rg-pane.rg-pane-bottom, .reactgrid-content .rg-pane.rg-pane-left, .reactgrid-content .rg-pane.rg-pane-right {
  background-color: #fff;
  position: sticky;
}

.reactgrid-content .rg-pane.rg-pane-top {
  top: 0;
}

.reactgrid-content .rg-pane.rg-pane-bottom {
  bottom: 0;
}

.reactgrid-content .rg-pane.rg-pane-left {
  left: 0;
}

.reactgrid-content .rg-pane.rg-pane-right {
  right: 0;
}

.reactgrid-content .rg-pane .rg-cell {
  box-sizing: border-box;
  white-space: nowrap;
  touch-action: auto;
  outline: none;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
  font-size: 1em;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.reactgrid-content .rg-pane .rg-cell .rg-touch-resize-handle {
  pointer-events: auto;
  width: 11px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.reactgrid-content .rg-pane .rg-cell .rg-touch-resize-handle .rg-resize-handle {
  width: 6px;
  height: 100%;
  position: absolute;
  right: 0;
}

.reactgrid-content .rg-pane .rg-cell .rg-touch-resize-handle .rg-resize-handle:hover {
  cursor: col-resize;
  background-color: #3579f8;
}

.reactgrid-content .rg-pane .rg-cell .rg-groupId {
  font-size: .8em;
  position: absolute;
  top: 4px;
  right: 4px;
}

.reactgrid-content .rg-pane .rg-cell-focus, .reactgrid-content .rg-pane .rg-cell-highlight {
  pointer-events: none;
  box-sizing: border-box;
  border: 2px solid #3579f8;
  position: absolute;
}

.reactgrid-content .rg-pane .rg-touch-fill-handle {
  touch-action: none;
  pointer-events: auto;
  background-color: #ffffff03;
  width: 40px;
  height: 40px;
  position: absolute;
}

.reactgrid-content .rg-pane .rg-touch-fill-handle .rg-fill-handle {
  cursor: crosshair;
  background-color: #3579f8;
  background-clip: content-box;
  border: 1px solid #fff;
  width: 6.5px;
  height: 6.5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - .5px), calc(-50% - .5px));
}

.reactgrid-content .rg-pane .rg-partial-area {
  pointer-events: none;
  box-sizing: border-box;
  position: absolute;
}

.reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-part {
  border: 1px dashed #000;
}

.reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-selected-range {
  background-color: #3579f859;
  border: 1px solid #3579f8;
}

.reactgrid-content .rg-pane-shadow {
  position: sticky;
}

.reactgrid-content .rg-pane-shadow.shadow-top {
  pointer-events: none;
  top: 0;
  box-shadow: 2px 2px 3px 1px #0000000f;
}

.reactgrid-content .rg-pane-shadow.shadow-left {
  pointer-events: none;
  left: 0;
  box-shadow: 5px 0 3px -2px #0000000f;
}

.reactgrid-content .rg-pane-shadow.shadow-bottom {
  pointer-events: none;
  bottom: 0;
  box-shadow: 2px -1px 3px 1px #0000000f;
}

.reactgrid-content .rg-pane-shadow.shadow-right {
  pointer-events: none;
  right: 0;
  box-shadow: -5px 0 3px -2px #0000000f;
}

.reactgrid-content .rg-pane-shadow.shadow-top-left-corner {
  box-shadow: 2px 3px 3px 1px #0000000f;
}

.reactgrid-content .rg-pane-shadow.shadow-top-right-corner {
  box-shadow: -2px 2px 3px 1px #0000000f;
}

.reactgrid-content .rg-pane-shadow.shadow-bottom-left-corner {
  box-shadow: 2px -2px 3px 1px #0000000f;
}

.reactgrid-content .rg-pane-shadow.shadow-bottom-right-corner {
  box-shadow: -2px -2px 3px 1px #0000000f;
}

.reactgrid-content .rg-context-menu {
  z-index: 1000;
  background-color: #fff;
  font-size: 1em;
  position: fixed;
  box-shadow: 0 0 8px 2px #0000000f;
}

.reactgrid-content .rg-context-menu .rg-context-menu-option {
  cursor: pointer;
  padding: 8px 20px 8px 15px;
}

.reactgrid-content .rg-context-menu .rg-context-menu-option:hover {
  background-color: #f2f2f2;
}

.reactgrid-content .rg-shadow {
  opacity: .1;
  z-index: 4;
  background-color: #000;
  position: absolute;
}

.reactgrid-content .rg-column-resize-hint {
  background-color: #74b9ff;
  border-radius: 0 5px 5px 0;
  padding: 5px;
  position: absolute;
}

.reactgrid-content .rg-line {
  z-index: 4;
  background-color: #74b9ff;
  position: absolute;
}

.reactgrid-content .rg-line-horizontal {
  height: 2px;
  left: 0;
}

.reactgrid-content .rg-line-vertical {
  width: 2px;
  top: 0;
}

.reactgrid-content .rg-hidden-element {
  opacity: 0;
  border: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 50%;
  left: 50%;
}

.json-container {
  background: #fffefc;
  outline: 1px solid #ccc;
  margin: 5px;
  display: block;
  position: relative;
}

.json-breadcrumb {
  z-index: 1;
  color: #3c3836;
  background: #fffefc;
  border-bottom: 1px solid #eceadf;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 3.5em;
  font: 1em monospace;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.json-breadcrumb > span:first-child:before {
  counter-reset: line-number var(--line-number);
  content: counter(line-number);
  text-align: right;
  color: #3c3836;
  background: #f8f6ef;
  width: 3em;
  height: 100%;
  padding-right: .5em;
  display: inline-block;
  position: absolute;
  left: 0;
}

.json-breadcrumb > span {
  white-space: nowrap;
}

.json-breadcrumb > span:nth-child(n+2):before {
  content: ">";
  color: #948b86;
  padding: 0 .5em;
}

.json-view {
  counter-reset: line;
  margin: 0;
  overflow: auto;
}

.json-view > span:before {
  counter-increment: line;
  content: counter(line);
  text-align: right;
  color: #3c3836;
  background: #f8f6ef;
  width: 3em;
  padding-right: .5em;
  display: inline-block;
  position: sticky;
  left: 0;
}

.json-view {
  max-height: calc(max(85vh, 700px));
}

.invoice-log-row .json-view {
  max-height: calc(max(40vh, 350px));
}

.json-view .string {
  color: #458588;
}

.json-view .null, .json-view .boolean, .json-view .number {
  color: #b16286;
}

.json-view .key {
  color: #6f8815;
}

.json-view .diff-rm {
  background-color: #ffe9e3;
}

.json-view .diff-add {
  background-color: #e5fcf1;
}

.json-view .diff-rm, .json-view .diff-add {
  position: relative;
}

.json-view .diff-rm:before, .json-view .diff-add:before {
  position: absolute;
}

.json-view .diff-rm:before {
  content: "-";
  color: red;
}

.json-view .diff-add:before {
  content: "+";
  color: green;
}

.invoice-details .ant-form-item {
  margin-bottom: 0;
}

.invoice-change-container {
  border-left: .3em solid rgb(var(--accent-color));
  background: linear-gradient(to right, rgba(var(--accent-color), .05), rgba(var(--accent-color), 0)) 0 / 5em 100% repeat-y;
  box-shadow: -10px 0 5px -5px rgba(var(--accent-color), .05);
  border-bottom: 1px solid #ccc;
}

.invoice-change-container:nth-child(3) {
  border-top: 1px solid #ccc;
  margin-top: 3px;
}

.invoice-change-row {
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  min-height: 2em;
  padding: 0 10px;
  display: grid;
}

.invoice-change-row > * {
  width: fit-content;
}

.file-list {
  flex-flow: wrap;
  justify-content: space-evenly;
  gap: 1em;
  display: flex;
}

.invoice-change-container:nth-child(odd) {
  background: linear-gradient(to right, rgba(var(--accent-color), .05), rgba(var(--accent-color), 0)) 0 / 5em 100% repeat-y, #f7f7f7;
}

.invoice-change-row > button {
  width: 2em;
  height: 2em;
}

div.invoice-log-list {
  overflow-wrap: anywhere;
  font-size: small;
}

.invoice-log-row {
  grid-template-columns: 65px 1fr;
  justify-content: space-between;
  align-items: center;
  min-height: 1.15em;
  padding: 0 5px;
  display: grid;
}

.invoice-log-row p {
  margin: .25em 0;
}

.invoice-log-row div {
  min-width: 0;
}

.invoice-change-list > div:nth-child(2n) > .invoice-log-list > div:nth-child(odd) {
  background-color: #f2f2f2;
}

.invoice-change-list > div:nth-child(odd) > div.invoice-log-list {
  background-color: #fff;
}

.invoice-change-list > div:nth-child(odd) > div.invoice-log-list > div:nth-child(2n) {
  background-color: #f2f2f2;
}

.rg-pane.rg-pane-left {
  z-index: 1;
}

.rg-pane.rg-pane-top {
  z-index: 2 !important;
}

.rg-pane.rg-pane-right.rg-pane-top, .rg-pane.rg-pane-left.rg-pane-top {
  z-index: 3 !important;
}

body {
  margin: 0;
  padding: 0;
}
/*# sourceMappingURL=index.501f97d2.css.map */
