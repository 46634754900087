.invoice-details .ant-form-item {
  margin-bottom: 0px; /* https://stackoverflow.com/a/56786409 */
}

.invoice-change-container {
  border-left: 0.3em solid rgb(var(--accent-color));
  border-bottom: 1px solid #ccc;
  background: linear-gradient(
      to right,
      rgba(var(--accent-color), 0.05),
      rgba(var(--accent-color), 0)
    )
    0 / 5em 100% repeat-y;
  box-shadow: -10px 0 5px -5px rgba(var(--accent-color), 0.05);
}

.invoice-change-container:nth-child(3) {
  border-top: 1px solid #ccc;
  margin-top: 3px;
}

.invoice-change-row {
  min-height: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.invoice-change-row > * {
  width: fit-content;
}

.file-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1em;
  flex-wrap: wrap;
}

.invoice-change-container:nth-child(odd) {
  background:
    linear-gradient(
        to right,
        rgba(var(--accent-color), 0.05),
        rgba(var(--accent-color), 0)
      )
      0 / 5em 100% repeat-y,
    #f7f7f7;
}

.invoice-change-row > button {
  height: 2em;
  width: 2em;
}

div.invoice-log-list {
  font-size: small;
  overflow-wrap: anywhere;
}

.invoice-log-row {
  min-height: 1.15em;
  display: grid;
  grid-template-columns: 65px 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.invoice-log-row p {
  margin: 0.25em 0;
}

.invoice-log-row div {
  min-width: 0; /* https://stackoverflow.com/a/66689926 */
}

.invoice-change-list
  > div:nth-child(even)
  > .invoice-log-list
  > div:nth-child(odd) {
  background-color: #f2f2f2;
}

.invoice-change-list > div:nth-child(odd) > div.invoice-log-list {
  background-color: white;
}

.invoice-change-list
  > div:nth-child(odd)
  > div.invoice-log-list
  > div:nth-child(even) {
  background-color: #f2f2f2;
}
