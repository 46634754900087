.json-container {
  display: block;
  position: relative;
  background: #fffefc;
  outline: 1px solid #ccc;
  margin: 5px;
}

.json-breadcrumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  padding-left: 3.5em;
  color: #3c3836;
  background: #fffefc;
  font: 1em monospace;
  border-bottom: 1px solid #eceadf;
  display: flex;
  flex-wrap: wrap;
}

.json-breadcrumb > span:first-child:before {
  counter-reset: line-number var(--line-number);
  content: counter(line-number);
  display: inline-block;
  background: #f8f6ef;
  position: absolute;
  left: 0;
  height: 100%;
  width: 3em;
  text-align: right;
  padding-right: 0.5em;
  color: #3c3836;
}

.json-breadcrumb > span {
  white-space: nowrap;
}

.json-breadcrumb > span:nth-child(1n + 2)::before {
  content: ">";
  padding: 0 0.5em;
  color: #948b86;
}

.json-view {
  counter-reset: line;
  /* position: relative; */
  overflow: auto;
  overflow-x: auto;
  margin: 0;
}

.json-view > span::before {
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  background: #f8f6ef;
  position: sticky;
  left: 0;
  width: 3em;
  text-align: right;
  padding-right: 0.5em;
  color: #3c3836;
}

.json-view {
  max-height: calc(max(85vh, 700px));
}

.invoice-log-row .json-view {
  max-height: calc(max(40vh, 350px));
}

.json-view .string {
  color: #458588;
}

.json-view .null,
.json-view .boolean,
.json-view .number {
  color: #b16286;
}

.json-view .key {
  color: #6f8815;
}

.json-view .diff-rm {
  background-color: #ffe9e3;
}

.json-view .diff-add {
  background-color: #e5fcf1;
}

.json-view .diff-rm,
.json-view .diff-add {
  position: relative;
}

.json-view .diff-rm::before,
.json-view .diff-add::before {
  position: absolute;
}

.json-view .diff-rm::before {
  content: "-";
  color: red;
}

.json-view .diff-add::before {
  content: "+";
  color: green;
}
